<template>
  <v-container>
 
<v-divider
  inset
></v-divider>  
<v-row >
      <v-col align="center" justify="center" class="ma-3"  xs="6" sm="6" md="6"  >
            <h2 class="header1">Team Leads</h2>
      </v-col>
    
</v-row>
      <v-row class="mp-5" align="center" justify="center"  no-gutters>

       
     
              <v-col  align="center" justify="center" class="ma-5"  xs="3" sm="3" md="3"  v-for="geek in geeks" :key="geek.name">
  
                 <v-card flat class="text-xs-center ma-3" >
                    <v-responsive class="pt-4">
                        <v-avatar size="100" class="gray lighten-2">
                        <v-img  
                        :src="geek.picture"      
                        ></v-img>
                        </v-avatar>
                        <v-card-text>
                        <div class="subheading">{{geek.name}}</div>
                        <div class="gray--text">{{geek.role}}</div>
                        </v-card-text>
                        <v-card-action>
                        
                            <v-icon small left>{{geek.icon}}</v-icon>
                            
                        
                        </v-card-action>
                    </v-responsive>
                </v-card>


            </v-col>
             
      
      </v-row>

  </v-container>
</template>


<script>
  export default {
   
    data: () => ({
      geeks: [
        {
          name: 'Yafet Birhanu',
          picture: '/team_yafet.jpg',
          role: 'System Designer & QAQC',
          icon: 'mdi-twitter'
        },
        {
          name: 'Abdurazak Abrar',
          picture: '/team_abdu.jpg',
          role: 'Front-end developer & Software Tester',
          icon: 'mdi-twitter'
        },
        {
          name: 'Andualem Aklilu',
          picture: '/team_andualem.jpg',
          role: 'GIS Professional & Data Analyst',
          icon: 'mdi-linkedin'
        }
      ]


    }),
  }
</script>
