<template>
  <v-container>

 


      <v-row class="mp-5 brown lighten-5" justify="center"  no-gutters>
     
              <v-col class="ma-5 justify-center" xs="12" sm="auto" md="auto">
                
                <v-card class="grey lighten-3"  max-width="240">
                    <v-img  
                    src="../assets/app_marathon.jpg"
                    max-height="300px"      
                    ></v-img>
                    <v-card-title>
                    Delivery App 
                    </v-card-title>
                    <v-card-subtitle>
                    developed for Marathon Express <br> To be launched soon.
                    </v-card-subtitle>
                </v-card>
            </v-col>
             <v-col class="ma-5 justify-center" xs="12" sm="auto" md="auto">
                <v-card class="grey lighten-3" max-width="240" >
                <v-img  
                src="../assets/app_book.jpg"
                 max-height="300px"         
                ></v-img>
                <v-card-title>
                Berhan Books
                </v-card-title>
                <v-card-subtitle>
                Managed by Addistag Systems <br> Over 5k downloads.
                </v-card-subtitle>
                </v-card>
            </v-col>
             <v-col class="ma-5 justify-center" xs="12" sm="auto" md="auto">
               <v-card class="grey lighten-3" max-width="240" >
                    <v-img 
                    src="../assets/app_united.jpg"
                    max-height="300px"  
                        
                    ></v-img>
                    <v-card-title>
                    ATM Locator 
                    </v-card-title>
                    <v-card-subtitle>
                    developed for Hibret Bank Sc <br> Published on Hibret bank site.
                    </v-card-subtitle>
                </v-card>
            </v-col>

             <v-col class="ma-5 justify-center" xs="12" sm="auto" md="auto">
               <v-card class="grey lighten-3" max-width="240" >
                    <v-img 
                    src="../assets/app_calendar.jpg"
                    height="300px"  
                        
                    ></v-img>
                    <v-card-title>
                    Ethiopian Calendar 
                    </v-card-title>
                    <v-card-subtitle>
                    Managed by Addistag Systems <br> Over 50k downloads.
                    </v-card-subtitle>
                </v-card>
            </v-col>
      
      </v-row>

  </v-container>
</template>


<script>
  export default {
   
    data: () => ({
      apps: [
        {
          title: 'Delivery App for Marathon Express',
          href: 'https://github.com/vuetifyjs/vuetify-loader'
        },
        {
          title: 'ATM Locator App for Hibret Bank',
          href: 'https://github.com/vuetifyjs/vuetify'
        },
        {
          title: 'Berhan Books - Ebook and Audiobook Market',
          href: 'https://github.com/vuetifyjs/awesome-vuetify'
        }
      ]


    }),
  }
</script>
