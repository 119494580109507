<template>
  <v-app>
    <v-toolbar app dark color="#7f4e08ff" max-height="70" >
      <v-col class="d-flex align-center"  xs="0" md="3">
      <div class="d-flex align-center" >
        <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain    
            src = "./assets/logo_and_name.svg"
            width="250"
        />
      </div>
</v-col>
      
      <v-col class="d-flex align-center"  xs="0" md="3">
<v-spacer></v-spacer>
      </v-col>

      <v-col  class="d-flex  justify-start"  xs="6"  md="3">
            
            <v-btn class="d-flex  white--text" href="mailto:yafet.birhanu@gmail.com" target="_blank" text>
              <v-icon size="24px">mdi-email</v-icon>
            <span >Email</span>
           
      </v-btn>

      </v-col>



            <v-col  class="d-flex  justify-start"  xs="6"  md="3">           


                <v-btn class="d-flex  white--text" icon href="tel:+251912200120"
                  target="_blank"
                  text>
              <v-icon size="24px">mdi-whatsapp</v-icon> 
              <v-icon size="24px">mdi-phone</v-icon> <span>+251 912 20 0120</span>          
              </v-btn>
      </v-col>




    </v-toolbar>

    <v-main >
        <HelloWorld/> 
       <app_marathon/>
        <web_apps/>  
        <team_leads/>
        <footer_cont/>
     
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import app_marathon from './components/app_marathon';
import web_apps from './components/web_apps';
import team_leads from './components/team_leads';
import footer_cont from './components/footer_cont';

export default {
  name: 'App',

  components: {
      HelloWorld,
      app_marathon,
      web_apps,
    team_leads,
    footer_cont,
  
},

  data: () => ({
    //
  }),
};
</script>
