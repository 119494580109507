<template>
  <v-container >
          <v-row  class="mb-4 " justify="center" >
     
            <v-col  class="mx-5 my-2 justify-center" xs="12" sm="3" md="3">
                  <partner_lomi/> 
  
              </v-col>

               <v-col  class="mx-5 my-2 justify-center" xs="12" sm="6" md="6">
                  <activity_list/>
               </v-col>
          </v-row>
</v-container>
</template>

<script>
import partner_lomi from './partner_lomi';
import activity_list from './activity_list.vue';


export default {
  name: 'App',

  components: {
    partner_lomi,
    activity_list,
},

  data: () => ({
    //
  }),
};
</script>