import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      
                primary: '#4a4a31ff',
                secondary: '#a57321ff',
                accent: '#f79c19ff'
         

      },
});
