<template>
  <v-container>
    <v-row class="text-center justify-center" no-gutters>
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_addistag.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col  class="mb-4 justify-center" xs="12" sm="9" md = "9" >

        <p  class="font-weight-black  brown--text lighten-1">
          data analysis | system design | apps development
        </p>

        <p class="subheading font-weight-regular">
          <strong class="brown--text lighten-1">Addistag Systems</strong>
           , founded in 2015, has published a number of mobile and web apps for clients active in variety of sectors. 

        </p>
        <p>We provide software solutions with the latest technology. 
          Completed a number projects with success, we relay on our experience and diversity of our team. 
          Check out some of our projects below: </p>



      </v-col>





    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'home_page',

    data: () => ({
     
    }),
  }
</script>
