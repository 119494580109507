<template>
  <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      class="brown lighten-1 white--text text-center"
    >
 
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-4 white--text"
          icon
        >
        <v-btn class="mx-4 white--text" icon href="https://www.facebook.com/addistag" target="_blank">
          <v-icon size="24px" >
            {{ icon.icon }}
          </v-icon>
        </v-btn>
        </v-btn>
      </v-card-text>




                <v-row  justify="center" >
     
            <v-col  class="ma-5 justify-center" xs="12" sm="5" md="5">
                  <p>Feel free to reach us for mobile application development, ERP project consultations and for interests to scale-up or colaborate on existing projects/products. </p>
  
              </v-col>

               <v-col  class="ma-5 justify-center" xs="12" sm="5" md="5">
                 
               <div>
                    <v-btn class="mx-4 white--text" icon href="tel:+251912200120">
                        <v-icon size="24px">mdi-whatsapp</v-icon> 
                    <v-icon size="24px">mdi-phone</v-icon> <span>+251 912 20 0120</span>          
                    </v-btn>
                </div> 
                <div>
                    <v-btn class="mx-4 white--text" icon href="mailto:yafet.birhanu@gmail.com" >
                    <v-icon  size="24px">mdi-email</v-icon> <span style = "text-transform: lowercase">yafet.birhanu@gmail.com</span>          
                    </v-btn>
                </div>
        
  <p  class="brown--text lighten-1">_________________________________________________________________________________________________</p>
               
               </v-col>
          </v-row>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Addistag Systems</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        {icon: 'mdi-facebook', link: "https://www.facebook.com/addistag"},
        {icon: 'mdi-linkedin', link: "https://www.facebook.com/addistag"},
        {icon: 'mdi-playstore', link: "https://www.facebook.com/addistag"}
      ],
    }),

     contacts: [
        { text: '+251 912 20 0120', icon: 'mdi-phone' },
        { text: 'yafet.birhanu@gmail.com', icon: 'mdi-email' },      
      ],
  }
</script>