<template>
  <v-card
    class="mx-auto"
    max-width="344"
  >
    <v-img
       src="../assets/partner_50lomi.jpg"
      height="200px"
    ></v-img>

    <v-card-title>
      Partnered with 50Lomi Platform  
    </v-card-title>

    <v-card-subtitle>
      Market proved ERP Solutions, School Admin Apps, Vehicle tracking systems.
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        color="orange lighten-2"
        text
      >
        Colaborations
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          Completed vehcle tracking systems to track vehicles and prevent teft.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      show: false,
    }),
  }
</script>